import type {ApolloError} from '@apollo/client';
import type {GraphQLFormattedError} from 'graphql';

export enum ErrorSeverity {
  Ignorable = 0,
  Expected = 1,
  Unexpected = 2,
}

const DEFAULT_PATHS = [
  'metaobjectDefinition',
  'metafieldDefinition',
  'image',
  'discoveryConfiguration',
];

export function isExpectedAccessDeniedError(
  error: GraphQLFormattedError,
  paths: string[] = DEFAULT_PATHS,
) {
  return (
    error.extensions?.code === 'ACCESS_DENIED' &&
    paths.some((path) => error.path?.includes(path))
  );
}

export function isErrorExpected(
  error: ApolloError,
  paths: string[] = DEFAULT_PATHS,
) {
  return (
    error.graphQLErrors.length > 0 &&
    error.graphQLErrors.every((error) =>
      isExpectedAccessDeniedError(error, paths),
    ) &&
    !error.networkError
  );
}

export function categorizeGraphQLErrors(
  errors: readonly GraphQLFormattedError[],
  ignorableErrorPaths: string[] = [],
  paths: string[] = DEFAULT_PATHS,
): {error: GraphQLFormattedError; severity: ErrorSeverity}[] {
  return errors.map((error) => ({
    error,
    severity: (() => {
      if (isExpectedAccessDeniedError(error, ignorableErrorPaths)) {
        return ErrorSeverity.Ignorable;
      }
      if (isExpectedAccessDeniedError(error, paths)) {
        return ErrorSeverity.Expected;
      }
      return ErrorSeverity.Unexpected;
    })(),
  }));
}
